<template>
  <div id="tetris">
    <canvas id="tetrisCanvas" width="300" height="600"></canvas>
    <div class="ad-container">
      <Adsense
        data-ad-client="ca-pub-6699354447815429" 
        data-ad-slot="8230887469"
        data-ad-format="horizontal"
        data-full-width-responsive="true">
      </Adsense>
    </div>
  </div>
</template>

<script>
// import { useMeta } from 'vue-meta'
import { inject } from 'vue'

export default {
  setup() {

    const setMeta = inject('setMeta')
    setMeta({
      title: 'SoSoCEO - 사업하는 개발자',
      keywords: '테트리스,게임,tetris,html,game,예제,코드,소스,코딩,sosoceo',
      description: '챗지피티로 개발한 HTML 테트리스 게임입니다.',
      'og:title': 'SoSoCEO - 사업하는 개발자',
      'og:description': '챗지피티로 개발한 HTML 테트리스 게임입니다.',
      'og:image': 'https://geek9.kr/img/logo_big.e0186dc8.jpg',
      'og:url': 'https://geek9.kr/tetris',
      'canonical': 'https://geek9.kr/tetris'
    })
    //     useMeta( {
    //   title: 'SoSoCEO - 사업하는 개발자',
    //   link: [{rel: "canonical", href: 'https://geek9.kr/tetris'}],
    //   meta: [
    //     { vmid: 'charset_tetris', charset: 'utf-8' },
    //     { vmid: 'http-equiv_tetris', 'http-equiv': 'X-UA-Compatible', content: 'IE=edge,chrome=1' },
    //     { vmid: 'company_tetris', name: 'company', itemprop: 'name', content: 'GEEK9' },
    //     { vmid: 'keywords_tetris', name: 'keywords', content:'테트리스,게임,tetris,html,game,예제,코드,소스,코딩,sosoceo' },
    //     { vmid: 'viewport_tetris', name: 'viewport', content: 'width=device-width' },
    //     { vmid: 'description_tetris', name: 'description', content: '챗지피티로 개발한 HTML 테트리스 게임입니다.' },
    //     { vmid: 'theme-color_tetris', name: 'theme-color', content: '#ffa07a'},
    //     { vmid: 'og:site_name_tetris', property: 'og:site_name', content: 'GEEK9'},
    //     { vmid: 'og:description_tetris', property: 'og:description', content: '챗지피티로 개발한 HTML 테트리스 게임입니다.'},
    //     { vmid: 'og:title_tetris', property: 'og:title', content: 'SoSoCEO - 사업하는 개발자'},
    //     { vmid: 'og:image_tetris', property: 'og:image', content: 'https://geek9.kr/img/logo_big.e0186dc8.jpg'},
    //     { vmid: 'og:url_tetris', property: 'og:url', content: 'https://geek9.kr/tetris'}
    //   ]
    // })
    return {
    }
  },
  components: {
  },
  mounted() {
    this.api.setPage('tetris')
    console.log(this.api.getCookies('page'))
    this.init()
    this.$emit("setMenu", {page:'tetris'})

    // 키보드 이벤트 처리
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('keyup', this.handleKeyUp);

  },
  data(){
    return{
      spaceKeyHold:false,
      block : {
        x: 0,
        y: 0,
        color: 'blue',
        shape: [],
      },
      moveDownInterval:500,
      originalMoveDownInterval:500,
      lastMoveDownTime:0,
      fastMoveDownInterval:50,
      score:0,
      block_size:30,
      rows:20,
      cols:10,
      canvas:undefined,
      context:undefined,
      board:undefined,
    }
  },
  methods : {
    init() {
      
      this.canvas = document.getElementById('tetrisCanvas')
      this.context = this.canvas.getContext('2d')
      this.board = Array.from({ length: this.rows }, () => Array(this.cols).fill(0))
      this.block = this.generateRandomBlock()

      // 게임 시작
      this.gameLoop()
      
    },
    drawSquare(x, y, color) {
      this.context.fillStyle = color
      this.context.fillRect(x * this.block_size, y * this.block_size, this.block_size, this.block_size)
      this.context.strokeStyle = '#333'
      this.context.strokeRect(x * this.block_size, y * this.block_size, this.block_size, this.block_size)
    },
    drawBoard() {
      for (let row = 0; row < this.rows; row++) {
        for (let col = 0; col < this.cols; col++) {
          if (this.board[row][col]) {
            this.drawSquare(col, row, this.board[row][col])
          }
        }
      }
    },
    draw() {
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
      this.drawBoard()
      this.drawBlock()
      this.drawScore()
    },
    drawBlock() {
      for (let row = 0; row < this.block.shape.length; row++) {
        for (let col = 0; col < this.block.shape[row].length; col++) {
          if (this.block.shape[row][col]) {
            this.drawSquare(this.block.x + col, this.block.y + row, this.block.color);
          }
        }
      }
    },
    moveDown() {
      const currentTime = new Date().getTime();

      if (currentTime - this.lastMoveDownTime > this.moveDownInterval) {
        if (this.canMoveDown()) {
          this.block.y++;
        } else {
          // 블록이 바닥에 닿았을 때 보드에 고정시키고 새로운 블록 생성
          this.placeBlockOnBoard()
          this.clearFullRows()
          this.block = this.generateRandomBlock()
        }

        this.lastMoveDownTime = currentTime
      }
    },
    moveLeft() {
      if (this.canMoveLeft()) {
        this.block.x--
      }
    },
    moveRight() {
      if (this.canMoveRight()) {
        this.block.x++
      }
    },
    rotateBlock() {
      const originalShape = this.block.shape;
      const rotatedShape = [];

      for (let col = 0; col < originalShape[0].length; col++) {
        rotatedShape[col] = [];
        for (let row = 0; row < originalShape.length; row++) {
          rotatedShape[col][row] = originalShape[originalShape.length - 1 - row][col];
        }
      }

      if (this.canRotate(rotatedShape)) {
        this.block.shape = rotatedShape;
      }
    },
    placeBlockOnBoard() {
      for (let row = 0; row < this.block.shape.length; row++) {
        for (let col = 0; col < this.block.shape[row].length; col++) {
          if (this.block.shape[row][col]) {
            this.board[this.block.y + row][this.block.x + col] = this.block.color;
          }
        }
      }
    },
    canMoveDown() {
      for (let row = 0; row < this.block.shape.length; row++) {
        for (let col = 0; col < this.block.shape[row].length; col++) {
          if (this.block.shape[row][col] && (this.block.y + row + 1 >= this.rows || this.board[this.block.y + row + 1][this.block.x + col])) {
            return false;
          }
        }
      }
      return true;
    },
    canMoveLeft() {
      for (let row = 0; row < this.block.shape.length; row++) {
        for (let col = 0; col < this.block.shape[row].length; col++) {
          if (this.block.shape[row][col] && (this.block.x + col - 1 < 0 || this.board[this.block.y + row][this.block.x + col - 1])) {
            return false;
          }
        }
      }
      return true;
    },
    canMoveRight() {
      for (let row = 0; row < this.block.shape.length; row++) {
        for (let col = 0; col < this.block.shape[row].length; col++) {
          if (this.block.shape[row][col] && (this.block.x + col + 1 >= this.cols || this.board[this.block.y + row][this.block.x + col + 1])) {
            return false;
          }
        }
      }
      return true;
    },
    canRotate(rotatedShape) {
      for (let row = 0; row < rotatedShape.length; row++) {
        for (let col = 0; col < rotatedShape[row].length; col++) {
          if (
            rotatedShape[row][col] &&
            (this.block.y + row >= this.rows || this.block.x + col < 0 || this.block.x + col >= this.cols || this.board[this.block.y + row][this.block.x + col])
          ) {
            return false;
          }
        }
      }
      return true;
    },
    generateRandomBlock() {
      const shapes = [
        { shape: [[1, 1, 1, 1]], color: 'blue' },
        { shape: [[1, 1], [1, 1]], color: 'green' },
        { shape: [[1, 1, 1], [1, 0, 0]], color: 'red' },
        { shape: [[1, 1, 1], [0, 0, 1]], color: 'purple' },
        // 다른 모양 추가 가능
      ]

      const randomBlock = shapes[Math.floor(Math.random() * shapes.length)];

      return {
        x: Math.floor((this.cols - randomBlock.shape[0].length) / 2),
        y: 0,
        color: randomBlock.color,
        shape: randomBlock.shape,
      }
    },


    

    handleKeyDown(event) {
      switch (event.code) {
        case 'ArrowLeft':
          this.moveLeft()
          break
        case 'ArrowRight':
          this.moveRight()
          break
        case 'ArrowDown':
          this.moveDown()
          break
        case 'Space':
          this.spaceKeyHold = true
          this.fastMoveDown()
          break
        case 'ArrowUp':
          this.rotateBlock()
          break
        // 다른 키에 대한 처리도 추가 가능
      }
    },
    handleKeyUp(event) {
      switch (event.code) {
        case 'Space':
          this.spaceKeyHold = false
          this.moveDownInterval = this.originalMoveDownInterval
          break
      }
    },

    fastMoveDown() {
      this.moveDownInterval = this.spaceKeyHold ? this.fastMoveDownInterval : this.originalMoveDownInterval
    },

    // 행이 꽉 찼는지 확인하고 삭제한 후 점수를 계산하는 함수
    clearFullRows() {
      let row = this.rows - 1
      while (row >= 0) {
        if (this.board[row].every(cell => cell !== 0)) {
          // 행이 꽉 찼을 때
          this.board.splice(row, 1)
          this.board.unshift(Array(this.cols).fill(0))
          this.score += 100 // 단순히 100점씩 계산
        } else {
          row--
        }
      }
    },

    // 점수를 화면에 그리는 함수
    drawScore() {
      this.context.fillStyle = '#333'
      this.context.font = '20px Arial'
      this.context.fillText('Score: ' + this.score, 10, 30)
    },

    // 게임 루프 예제
    gameLoop() {
      this.moveDown()
      this.draw()
      requestAnimationFrame(this.gameLoop)
    }

  }
}
</script>

<style>

#tetris {
  height: 100vh;
  width:100%;
  margin: 0;
  text-align: center; 
}

#tetrisCanvas {
  border: 1px solid #333;
  overflow: hidden;
  z-index: 999999998; 
}
</style>
